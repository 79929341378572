<template>
  <tbody>
    <template v-if="isLoading">
      <tr
        v-for="i in rows"
        :key="`skeleton-tr-${i}`"
        class="v-data-table__empty-wrapper"
      >
        <td
          v-for="j in cols"
          :key="`skeleton-td-${j}`"
        >
          <v-skeleton-loader
            class="px-0"
            type="list-item"
          />
        </td>
      </tr>
    </template>

    <template v-else-if="!isLoading && itemsLength === 0">
      <tr class="tr-white">
        <td
          :colspan="cols"
          class="text-center td-empty"
        >
          No records
        </td>
      </tr>
    </template>
  </tbody>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      defalut: false
    },
    cols: {
      type: Number,
      default: 1
    },
    rows: {
      type: Number,
      default: 10
    },
    itemsLength: {
      type: Number,
      default: 0
    },
    requireSelectedManufacturer: {
      type: Boolean,
      default: false
    },
    enableAppSelector: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.td-empty {
  padding-top: 160px !important;
  padding-bottom: 160px !important;
}
.tr-white:hover {
  background: none !important;
}
</style>
