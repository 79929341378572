<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageTitleComponent title="블랙리스트 목록" />
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="auto">
        <v-btn class="mx-0" color="primary" :to="`/blacklist/add`">
          <v-icon>add</v-icon>
          <span class="pl-2">등록</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-simple-table class="tbl elevation-2">
          <template v-slot:default>
            <thead>
              <tr>
                <th v-for="item in headers" :key="item.text" :class="`text-${item.align}`">
                  {{ item.text }}
                </th>
              </tr>
            </thead>
            <TbodyPreRender :is-loading="isLoading" :cols="headers.length" :items-length="items.length" />
            <tbody v-if="!isLoading">
              <tr v-for="(item, i) in items" :key="`patient-item-${item.idx}`">
                <td class="text-center">
                  <router-link :to="`/blacklist/${item.idx}`">
                    {{ item.idx }}
                  </router-link>
                </td>
                <td class="text-center">
                  <router-link :to="`/blacklist/${item.idx}`">
                    {{ item.phone }}
                  </router-link>
                </td>
                <td class="text-center">
                  {{ item.reason }}
                </td>
                <td class="text-center">
                  {{ filterDatetimeFormat(item.created_at) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <!-- <v-row class="mb-5">
      <v-col cols="12">
        <PaginationComponent v-model="pagination" />
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import axios from 'axios'
import Config from '@/config'
import dayjs, { Ls } from 'dayjs'
import PaginationComponent from '../_elements/pagination/Pagination'
import PageTitleComponent from '../_elements/title/PageTitle'
import TbodyPreRender from '../_elements/table/TbodyPreRender'

const locations = [
  { text: '전체', value: 46 },
  { text: '서울', value: 2 },
  { text: '부산', value: 3 },
  { text: '대구', value: 4 },
  { text: '인천', value: 5 },
  { text: '광주', value: 6 },
  { text: '대전', value: 7 },
  { text: '울산', value: 8 },
  { text: '세종', value: 9 },
  { text: '경기', value: 10 },
  { text: '강원', value: 11 },
  { text: '충북', value: 12 },
  { text: '충남', value: 13 },
  { text: '전북', value: 14 },
  { text: '전남', value: 15 },
  { text: '경북', value: 16 },
  { text: '경남', value: 17 },
  { text: '제주', value: 18 }
]

export default {
  components: {
    PageTitleComponent,
    PaginationComponent,
    TbodyPreRender
  },
  data() {
    const { page, search } = this.$route.query
    return {
      isLoading: false,
      query: '',
      items: [],
      headers: [
        { text: 'Idx', align: 'center' },
        { text: '전화번호', align: 'center' },
        { text: '차단사유', align: 'center' },
        { text: '등록일', align: 'center' }
      ],
      locations,
      search,
      filterLocation: null,
      //   pagination: {
      //     page: +page,
      //     totalVisible: 10,
      //     length: 1000
      //   },
      countApplicants: []
    }
  },
  watch: {
    // pagination: {
    //   deep: true,
    //   immediate: true,
    //   handler() {
    //     this.fetch(this.pagination.page)
    //   }
    // },
    filterLocation() {
      this.fetch(this.pagination.page)
    }
  },
  mounted() {
    const { page } = this.$route.query
    this.fetch(page)
  },
  methods: {
    async fetch() {
      await axios.get(`${Config.Api.Service.url}/blackuser/all`).then(data => {
        this.items = data.data
      })
    },
    filterDateFormat(dateString) {
      return dayjs(dateString).format('YYYY-MM-DD')
    },
    filterDatetimeFormat(dateString) {
      return dayjs(dateString).format('YYYY-MM-DD HH:mm:ss')
    },
    onEnter() {
      this.$router.push({
        path: './patients',
        query: {
          page: 1,
          search: this.search
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tbl {
  ::v-deep table {
    min-width: 500px;
  }
}
.theme--light.v-data-table {
  ::v-deep table > thead > tr > th {
    background-color: #eeeeee;
  }
}
.v-skeleton-loader {
  ::v-deep .v-skeleton-loader__list-item {
    padding: 0px !important;
  }
}</style
>../patients/model/patientList./Item.vue
